"use client";

import { useEffect, useState } from "react";
import useIsMobile from "@/SupportingFiles/MobileProvider";
import { TBlogsType } from "@/types/strapi/Blogs/TBlogs";
import { TStrapiHomePage } from "@/types/strapi/Common/Common";
import dynamic from "next/dynamic";
import HelmetComponent from "@/SupportingFiles/HelmetComponent";
import Head from "next/head";
import { FRONTEND_DOMAIN } from "@/URLCollection/Domain";

// dynamic import
const Home = dynamic(() => import("../../Page/Desktop/Home/Home"));
const MHome = dynamic(() => import("../../Page/Mobile/MHome/MHome"));

export default function HomeContainer({
  homePageData,
  blogs,
  slideArray,
}: {
  homePageData: TStrapiHomePage | null;
  blogs: TBlogsType[];
  slideArray: any[];
}) {
  const isMobile = useIsMobile();

  if (isMobile === null) {
    // Optionally, return a loader or nothing during the initial render
    return null;
  }

  return (
    <>
      {isMobile ? (
        <MHome homePageData={homePageData} blogs={blogs} />
      ) : (
        <Home
          homePageData={homePageData}
          blogs={blogs}
          slideArray={slideArray}
        />
      )}
    </>
  );
}
