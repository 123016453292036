"use client";
import React, { ReactNode, useEffect } from "react";
import { logPageView } from "@/utils/analytics";
import { useRouter } from "next/navigation";

interface GAListenerProps {
    children: ReactNode;
  }

const GAListener: React.FC<GAListenerProps> = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    logPageView(); 
  }, [router]);

  return <>{children}</>; 
}

export default GAListener;
