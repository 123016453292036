"use client";

import { useState, useEffect } from "react";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean | null>(null); // Start with `null` to avoid hydration mismatch

  useEffect(() => {
    // Ensure this code runs only on the client side
    if (typeof window !== "undefined") {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobileDevice =
        /android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(
          userAgent
        );

      setIsMobile(isMobileDevice); // Set the correct value after hydration
    }
  }, []); // Run only once after the component mounts

  return isMobile;
};

export default useIsMobile;
