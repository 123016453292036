import(/* webpackMode: "eager" */ "/var/www/frontend/Production/b2c/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/frontend/Production/b2c/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["toast","error"] */ "/var/www/frontend/Production/b2c/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/var/www/frontend/Production/b2c/src/app/container/Home/HomeContainer.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/frontend/Production/b2c/src/app/GAListener.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["generateRandomNumberString"] */ "/var/www/frontend/Production/b2c/src/SupportingFiles/HelpingFunction.tsx");
